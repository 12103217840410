import React, { Component, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Clickable from "../three-components/Clickable";
import Draggable from "../three-components/Draggable";
import MovePad from "../three-components/MovePad";
import MovePadLevel_05 from "../three-components/MovePadLevel_05";
import MovePadLevel_01 from "../three-components/MovePadLevel_01";
import MovePadLevel_00 from "../three-components/MovePadLevel_00";
import LookControlsV2 from "../three-components/LookControlsV2";
import HDREnvironment from "../three-components/HDREnvironment";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingManager from "../utilities/LoadingManager";
// import Whiteboard from "../three-components/Whiteboard2";
// import WhiteboardControls from "../three-components/WhiteboardControls";
// import ImageViewer from "../three-components/ImageViewer";
import Heightmap from "../three-components/heightmap";
import ModalTrigger from "../three-components/modal-trigger";
import PostItNote from "../three-components/PostItNote";
import TextFieldDialog from "../components/TextFieldDialog";
import ImageModal from "../components/ImageModal";
import HelpModal from "../components/HelpModal";
import EmailModal from "../components/EmailModal";
import WelcomeModal from "../components/WelcomeModal";
import VideoViewer from "../three-components/VideoViewer";
import {IsBrowserCompatible} from "../libs/BrowserLib";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {Box, Button, DialogActions} from "@material-ui/core";
import {useIntercom} from "react-use-intercom";
import TriggerCube from "../three-components/trigger-cube";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { CopyShader } from "three/examples/jsm/shaders/CopyShader.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";



//todo - optimise images / model / pdf / video / bake / preload?
//todo - alternative to vercel as a backup - hand drop files onto AWS?


//deploy
const myNull = 0;
const AA_on = false;
const FXAA_on = false;
const GPU_on = false;

const PDFBoards = [

    // 01_cock down
    {
        image: "assets/pdf/01_ADVION/Brochure_Advion Cockroach Gel Marketing Leaflet - English.jpg",
        pdf:   "assets/pdf/01_ADVION/Brochure_Advion Cockroach Gel Marketing Leaflet - English.pdf",
        positionX: 17.266,
        positionY: 0.82,
        positionZ: (-5.149)-.7,
        rotationY: 180
    },
    {
        image: "assets/pdf/01_ADVION/Brochure_Advion Cockroach Gel Marketing Leaflet - English.jpg",
        pdf:   "assets/pdf/01_ADVION/Brochure_Advion Cockroach Gel Marketing Leaflet - English.pdf",
        positionX: 17.266,
        positionY: 0.82,
        positionZ: (-5.149)+.7,
        rotationY: 180
    },

    // 01_ant up
    {
        image: "assets/pdf/01_ADVION/Brochure_Optigard Ant Gel Bait_brochure_SG-1.jpg",
        pdf: "assets/pdf/01_ADVION/Brochure_Optigard Ant Gel Bait_brochure_SG.pdf",
        positionX: 23.06,
        positionY: (4.892)+.1,
        positionZ: (1.509) - .72,
        rotationY: 180
    },
    {
        image: "assets/pdf/01_ADVION/Brochure_Optigard Ant Gel Bait_brochure_SG-1.jpg",
        pdf: "assets/pdf/01_ADVION/Brochure_Optigard Ant Gel Bait_brochure_SG.pdf",
        positionX: 23.06,
        positionY: (4.892)+.1,
        positionZ: (1.509)+.7,
        rotationY: 180
    },

    {
        image: "assets/pdf/03_ALTRISET/Brochure_ Altriset.jpg",
        pdf: "assets/pdf/03_ALTRISET/Brochure_ Altriset termiticide Marketing Leaflet - English.pdf",
        positionX: (  -6.57 )-.7,
        positionY: (  0.59)+.2,
        positionZ: (-18.61)+.0,
        rotationY: -90
    },
    {
        image: "assets/pdf/03_ALTRISET/Brochure_ Altriset.jpg",
        pdf: "assets/pdf/03_ALTRISET/Brochure_ Altriset termiticide Marketing Leaflet - English.pdf",
        positionX: (  -6.57 )+.7,
        positionY: (  0.59)+.2,
        positionZ: (-18.61)+.0,
        rotationY: -90
    },
//////////
    {
        image: "assets/pdf/04_TALON/klerat_waxblocks.jpg",
        pdf: "assets/pdf/04_TALON/Brochure_klerat_waxblocks_.pdf",
        positionX: (  6.84 )-.7,
        positionY: (  0.59)+.2,
        positionZ: (19.22)+.0,
        rotationY: 90
    },
    {
        image: "assets/pdf/04_TALON/Talon.jpg",
        pdf: "assets/pdf/04_TALON/Brochure_Talon Pellet_SG.pdf",
        positionX: (  6.84 )+.7,
        positionY: (  0.59)+.2,
        positionZ: (19.22)+.0,
        rotationY: 90
    },
/////////////////
    // 02 down
    {
        image: "assets/pdf/02_DEMAND/Brochure_Demand.jpg",
        pdf: "assets/pdf/02_DEMAND/Brochure_Demand Duo 4pp Launch Brochure FLAME_SYN5592.pdf",
        positionX: (  -16.759 )-.0,
        positionY: (  0.59)+.2,
        positionZ: (6.73)-.7,
        rotationY: 0
    },
    {
        image: "assets/pdf/02_DEMAND/Brochure_Demand.jpg",
        pdf: "assets/pdf/02_DEMAND/Brochure_Demand Duo 4pp Launch Brochure FLAME_SYN5592.pdf",
        positionX: (  -16.759 )-.0,
        positionY: (  0.59)+.2,
        positionZ: (6.73)+.7,
        rotationY: 0
    },
    // 02 up
    {
        image: "assets/pdf/02_DEMAND/Brochure_ARILON.jpg",
        pdf: "assets/pdf/02_DEMAND/Brochure_ARILON 2pp A5 Brochure Update_RGB (1).pdf",
        positionX: (  -22.787 )-.0,
        positionY: (  4.86)+.2,
        positionZ: (1.07)-.7,
        rotationY: 0
    },
    {
        image: "assets/pdf/02_DEMAND/Brochure_ARILON.jpg",
        pdf: "assets/pdf/02_DEMAND/Brochure_ARILON 2pp A5 Brochure Update_RGB (1).pdf",
        positionX: (  -22.787 )-.0,
        positionY: (  4.86)+.2,
        positionZ: (1.07)+.7,
        rotationY: 0
    },
//////////////////////////////
    {
        image: "assets/pdf/05_VECTOR/Vector Control brochure - 50EC.png",
        pdf: "assets/pdf/05_VECTOR/Brochure_Actellic®50EC Marketing Leaflet - English (Page_View).pdf",
        positionX: (  29.467 )-.0,
        positionY: (  2.38)+.2,
        positionZ: (25.08)-.7,
        rotationY: 180
    },

    {
        image: "assets/pdf/05_VECTOR/Vector Control brochure - Icon EC.png",
        pdf: "assets/pdf/05_VECTOR/Brochure_Icon EC Marketing Leaflet - English (Page_View).pdf",
        positionX: (  29.467 )-.0,
        positionY: (  2.38)+.2,
        positionZ: (25.08)+.7,
        rotationY: 180
    },

    {
        image: "assets/pdf/05_VECTOR/Vector Control brochure - 300CS.png",
        pdf: "assets/pdf/05_VECTOR/Brochure_Actellic 300CS_(pages view)_2015_EN.pdf",

            positionX: (  29.467 )-.0,
            positionY: (  2.38)+.2,
            positionZ: (25.08)+2,
            rotationY: 180
    },
    {
        image: "assets/pdf/05_VECTOR/Vector Control brochure - 10CS.png",
        pdf: "assets/pdf/05_VECTOR/Brochure_icon_india_brochure_4pp-f_lr.pdf",
        positionX: (  29.467 )-.0,
        positionY: (  2.38)+.2,
        positionZ: (25.08)+3.3,
        rotationY: 180
    },
//////////////////////////
    {
        image: "assets/pdf/06_SPECIALTY/Brochure_Actellic.jpg",
        pdf: "assets/pdf/06_SPECIALTY/Brochure_Actellic_50EC.pdf",
        positionX: (  -29.55 )-.0,
        positionY: (  2.74)+.2,
        positionZ: (-23.32)-.7,
        rotationY: 0
    },
    {
        image: "assets/pdf/06_SPECIALTY/Brochure_demand.jpg",
        pdf: "assets/pdf/06_SPECIALTY/Brochure_demand_cs_marketing_leaflet_-_english.pdf",
        positionX: (  -29.55 )-.0,
        positionY: (  2.74)+.2,
        positionZ: (-23.32)-.7-1.1,
        rotationY: 0
    },
    ///
    {
        image: "assets/pdf/06_SPECIALTY/Brochure_klerat.jpg",
        pdf: "assets/pdf/06_SPECIALTY/Brochure_klerat_waxblocks_.pdf",
        positionX: (  -29.55 )-.0,
        positionY: (  1.92)+.2,
        positionZ: (-23.32)-.7,
        rotationY: 0
    },
    {
        image: "assets/pdf/06_SPECIALTY/Brochure_zyroxrfly.jpg",
        pdf: "assets/pdf/06_SPECIALTY/Brochure_zyroxrfly_granular_bait_marketing_leaflet_-_english.pdf",
        positionX: (  -29.55 )-.0,
        positionY: (  1.92)+.2,
        positionZ: (-23.32)-.7-1.1,
        rotationY: 0
    },
/////////////////


]

const BasicThreeExample = () => {
    const { isLoading, isAuthenticated } = useAuth0();
    const { boot, shutdown, hide, show, update } = useIntercom();

    const [controls, setControls] = useState();
    const mountRef = useRef(null);
    const [initialised, setInitialised] = useState(false);
    const [whiteboard, setWhiteboard] = useState();
    const [showLoadingScreen, setShowLoadingScreen] = useState(true);
    // useEffect(() => {
    //     console.log('Auth change')
    //     if(!controls) return;
    //     if(isLoading) controls.enabled = false;
    //     controls.enabled = isAuthenticated;
    // }, [isAuthenticated])

    useEffect(() => {
        if (!mountRef.current || initialised) return;

        setInitialised(true);

        //force intercom popup
        // show()

        const mouse = new THREE.Vector2();

        let composer1, composer2, fxaaPass;

        //Setup three
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        );

        //Create a loading manager
        new LoadingManager(
            () => setShowLoadingScreen(false)
        );

        //Does not work
        // camera.rotateY( 90 * THREE.Math.DEG2RAD )
        //this.camera.rotateY( 90 * THREE.Math.DEG2RAD )

        //Does not work
        // const cameraTargetPosition = new THREE.Vector3();
        // cameraTargetPosition.addVectors(1,1,1, 0,0,0);
        const renderer = new THREE.WebGLRenderer({
            antialias: true,
            // make your laptop hot:
            powerPreference: "high-performance",
        });


        const hudScene = new THREE.Scene();

        // Added this to enable PBR materials - might need tweaking
        // renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMapping = THREE.LinearToneMapping//trying this
        renderer.toneMappingExposure = 1.6;
        renderer.outputEncoding = THREE.sRGBEncoding;
        //

if (FXAA_on)
{
    renderer.autoClear = false;//what's this for?
    renderer.setPixelRatio(window.devicePixelRatio);//does this work?
}
        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);

        const clock = new THREE.Clock();
        const raycaster = new THREE.Raycaster();

        //Set up the HDR texture environment
        new HDREnvironment(scene, renderer, "assets/ppm.hdr");

        const renderPass = new RenderPass( scene, camera );

        if (FXAA_on) {
            fxaaPass = new ShaderPass(FXAAShader);
            const copyPass = new ShaderPass(CopyShader);

            composer1 = new EffectComposer(renderer);
            composer1.addPass(renderPass);
            composer1.addPass(copyPass);
        }

        //Add lights
        const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.6);
                hemiLight.color.setHSL(0.6, 1, 0.6);
                hemiLight.groundColor.setHSL(0.095, 1, 0.75);
                hemiLight.position.set(0, 50, 0);
                scene.add(hemiLight);
        // // const hemiLightHelper = new THREE.HemisphereLightHelper(hemiLight, 10);
        // // scene.add(hemiLightHelper);

        const pointLight = new THREE.PointLight( 0xffffff  , 12, 5 );
        pointLight.position.set( -18, 2.8, 0 );
        scene.add( pointLight );

        const pointLight2 = new THREE.PointLight( 0xffffff  , 7, 5 );
        pointLight2.position.set( 18, 2.8, 1 );
        scene.add( pointLight2 );


        const pointLight3 = new THREE.PointLight( 0xffffff  , 7, 10 );
        pointLight3.position.set( 27, 5.0, 24 );
        scene.add( pointLight3 );

        const pointLight4 = new THREE.PointLight( 0xffffff  , 7, 10 );
        pointLight4.position.set( -27, 5.0, -24 );
        scene.add( pointLight4 );

        const pointLight5 = new THREE.PointLight( 0xffffff  , 7, 10 );
        pointLight5.position.set( -27, 5.0, 24 );
        scene.add( pointLight5 );

        const pointLight6 = new THREE.PointLight( 0xffffff  , 7, 10 );
        pointLight6.position.set( 27, 5.0, -24 );
        scene.add( pointLight6 );

        // const pointLightHelper = new THREE.PointLightHelper(pointLight3, 2);
        // scene.add(pointLightHelper);


        // const dirLight = new THREE.DirectionalLight(0xffffff, 1);
        // dirLight.color.setHSL(0.1, 1, 0.95);
        // dirLight.position.set(-1, 1.75, 1);
        // dirLight.position.multiplyScalar(30);
        // scene.add(dirLight);
        // dirLight.castShadow = true;
        // dirLight.shadow.mapSize.width = 2048;
        // dirLight.shadow.mapSize.height = 2048;
        // const d = 50;
        // dirLight.shadow.camera.left = -d;
        // dirLight.shadow.camera.right = d;
        // dirLight.shadow.camera.top = d;
        // dirLight.shadow.camera.bottom = -d;
        // dirLight.shadow.camera.far = 3500;
        // dirLight.shadow.bias = -0.0001;
        // const dirLightHelper = new THREE.DirectionalLightHelper(dirLight, 10);
        // scene.add(dirLightHelper);

        const heightmap = new Heightmap(
            scene,
            "assets/images/heightmap3.png",
            camera,
            hudScene
        );

        //Create PDF boards
        PDFBoards.forEach((board) => {
            const trigger = new ModalTrigger(
                scene,
                () => window.open(board.pdf, "_blank"),
                board.image
            );
            trigger.setPosition(board.positionX, board.positionY, board.positionZ);
            trigger.setRotation(board.rotationY)
        });

        // const modalTrigger = new ModalTrigger(
        //     scene,
        //     setImageModalOpen,
        //     "/assets/images/contactus.png"
        // );
        // // contact us box
        // modalTrigger.setPosition(-8, 1.1, -6);
        // modalTrigger.setRotation(.001);

        const helpTrigger2 = new TriggerCube(
            scene,
            setHelpModalOpen,
            "/assets/images/help.jpg"
        );
        // help  box
        helpTrigger2.setPosition(-4.7,1, 0.0);
        helpTrigger2.setRotation(72,-90,0);

        const helpTrigger = new TriggerCube(
            scene,
            setHelpModalOpen,
            "/assets/images/help.jpg"
        );
        // help  box
        helpTrigger.setPosition(4.7,1, 0.0);
        helpTrigger.setRotation(-72,-90,0);



        const chatTrigger = new TriggerCube(
            scene,
            // setHelpModalOpen,
            setEmailModalOpen,
            // () => window.Intercom('show'),
            "/assets/images/speak.jpg"
        );
        // chat  box
        chatTrigger.setPosition(-0,1, -4.7);
        chatTrigger.setRotation(-72,0,0);

        const chatTrigger2 = new TriggerCube(
            scene,
            // setHelpModalOpen,
            setEmailModalOpen,
            // () => window.Intercom('show'),
            "/assets/images/speak.jpg"
        );
        // chat  box
        chatTrigger2.setPosition(-0,1, 4.7);
        chatTrigger2.setRotation(72,0,0);

        // const postItNote1 = new PostItNote(
        //   scene,
        //   0.0,
        //   1.1,
        //   0.0,
        //   0.0,
        //   "assets/textures/postit_1.png"
        // );


        //Add Controls
        const controls = new LookControlsV2(camera, renderer, scene);
        controls.enabled = true;
        console.log(isAuthenticated);
        setControls(controls);

        controls.postMove = (pos) => heightmap.onMove(pos);

        //Add room
        // const loader = new GLTFLoader();
        const loader = new GLTFLoader(LoadingManager.instance);

        //Load room
        loader.load(
            "assets/PPM_Syngenta_V04.glb",
            function (gltf) {
                const room = gltf.scene;
                room.castShadow = true;
                room.receiveShadow = true;
                scene.add(room);
                room.position.set(0, 0,0);
            }
        );

        //Load helmet
        // loader.load(
        //     "assets/models/gltf/DamagedHelmet/DamagedHelmet.gltf",
        //     function (gltf) {
        //         const helmet = gltf.scene;
        //         helmet.castShadow = true;
        //         helmet.receiveShadow = true;
        //         scene.add(helmet);
        //         helmet.position.set(4, -5.7, -6);
        //         helmet.scale.setScalar(0.3);
        //         helmet.rotateY(-Math.PI / 2);
        //     }
        // );
//commented out - we are not  sing it
        // const whiteboard = new Whiteboard(
        //   scene,
        //   camera,
        //   renderer,
        //   3.2,
        //   1.8,
        //   () => (controls.enabled = false),
        //   () => (controls.enabled = true),
        //   () => setTextFieldOpen(true)
        // );
        // whiteboard.setPosition(1, -5.9, -10.4);
        // setWhiteboard(whiteboard);

        // const whiteboardControls = new WhiteboardControls(whiteboard, scene);

        const pad_01_home = new MovePadLevel_00("pad_01_home", scene, controls);
        pad_01_home.position = new THREE.Vector3(0, .12, 0);
        const pad_01_home_Sprite = new MovePad("pad_01_home_Sprite", scene, controls);
        pad_01_home_Sprite.position = new THREE.Vector3(0, .12, 0);

        const pad_02_ridmus = new MovePadLevel_00("pad_02_ridmus", scene, controls);
        pad_02_ridmus.position = new THREE.Vector3(0, .01, -14);
        const pad_02_ridmus_Sprite = new MovePad("pad_02_ridmus_sprite", scene, controls);
        pad_02_ridmus_Sprite.position = new THREE.Vector3(0, .01, -14);


        const pad_02_ridmus_L = new MovePadLevel_00("pad_02_ridmus_L", scene, controls);
        pad_02_ridmus_L.position = new THREE.Vector3(-6.2, .01, -16.2);
        const pad_02_ridmus_L_sprite = new MovePad("pad_02_ridmus_L_sprite", scene, controls);
        pad_02_ridmus_L_sprite.position = new THREE.Vector3(-6.2, .01, -16.2);


        const pad_02_ridmus_R = new MovePadLevel_00("pad_02_ridmus_R", scene, controls);
        pad_02_ridmus_R.position = new THREE.Vector3(6.2, .01, -16.2);
        const pad_02_ridmus_R_sprite = new MovePad("pad_02_ridmus_R_sprite", scene, controls);
        pad_02_ridmus_R_sprite.position = new THREE.Vector3(6.2, .01, -16.2);

        const pad_03_pestapp = new MovePadLevel_00("pad_03_pestapp", scene, controls);
        pad_03_pestapp.position = new THREE.Vector3(0, .01, 14);
        const pad_03_pestapp_sprite = new MovePad("pad_03_pestapp_sprite", scene, controls);
        pad_03_pestapp_sprite.position = new THREE.Vector3(0, .01, 14);

        const pad_03_pestapp_L = new MovePadLevel_00("pad_03_pestapp_L", scene, controls);
        pad_03_pestapp_L.position = new THREE.Vector3(6.2, .01, 16.2);
        const pad_03_pestapp_L_sprite = new MovePad("pad_03_pestapp_L_sprite", scene, controls);
        pad_03_pestapp_L_sprite.position = new THREE.Vector3(6.2, .01, 16.2);

        const pad_03_pestapp_R = new MovePadLevel_00("pad_03_pestapp_R", scene, controls);
        pad_03_pestapp_R.position = new THREE.Vector3(-6.2, .01, 16.2);
        const pad_03_pestapp_R_sprite = new MovePad("pad_03_pestapp_R_sprite", scene, controls);
        pad_03_pestapp_R_sprite.position = new THREE.Vector3(-6.2, .01, 16.2);


        const pad_04_talon = new MovePadLevel_00("pad_04_talon", scene, controls);
        pad_04_talon.position = new THREE.Vector3(-10, .01, 0);
        const pad_04_talon_sprite = new MovePad("pad_04_talon_sprite", scene, controls);
        pad_04_talon_sprite.position = new THREE.Vector3(-10, .01, 0);

        const pad_04_talon_L = new MovePadLevel_00("pad_04_talon_L", scene, controls);
        pad_04_talon_L.position = new THREE.Vector3(-13, .01, 6.2);
        const pad_04_talon_L_sprite = new MovePad("pad_04_talon_L_sprite", scene, controls);
        pad_04_talon_L_sprite.position = new THREE.Vector3(-13, .01, 6.2);


        const pad_04_talon_R = new MovePadLevel_00("pad_04_talon_R", scene, controls);
        pad_04_talon_R.position = new THREE.Vector3(-13, .01, -6.2);
        const pad_04_talon_R_sprite = new MovePad("pad_04_talon_R_sprite", scene, controls);
        pad_04_talon_R_sprite.position = new THREE.Vector3(-13, .01, -6.2);

        const pad_04_talon_U = new MovePadLevel_01("pad_04_talon_U", scene, controls);
        pad_04_talon_U.position = new THREE.Vector3(-17, 3.75, 5.5);
        // const pad_04_talon_U_sprite = new MovePad("pad_04_talon_U_sprite, scene, controls);
        // pad_04_talon_U_sprite.position = new THREE.Vector3(-17, 3.75, 5.5);

        const pad_05_antbait = new MovePadLevel_00("pad_05_antbait", scene, controls);
        pad_05_antbait.position = new THREE.Vector3(10, .01, 1.25);
        const pad_05_antbait_sprite = new MovePad("pad_05_antbait_sprite", scene, controls);
        pad_05_antbait_sprite.position = new THREE.Vector3(10, .01, 1.25);

        const pad_05_antbait_L = new MovePadLevel_00("pad_05_antbait_L", scene, controls);
        pad_05_antbait_L.position = new THREE.Vector3(13, .01, -3.25);
        const pad_05_antbait_L_sprite = new MovePad("pad_05_antbait_L_sprite", scene, controls);
        pad_05_antbait_L_sprite.position = new THREE.Vector3(13, .01, -3.25);


        const pad_05_antbait_R = new MovePadLevel_00("pad_05_antbait_R", scene, controls);
        pad_05_antbait_R.position = new THREE.Vector3(13, .01, 5.75);
        const pad_05_antbait_R_sprite = new MovePad("pad_05_antbait_R_spritev", scene, controls);
        pad_05_antbait_R_sprite.position = new THREE.Vector3(13, .01, 5.75);

        const pad_05_antbait_U = new MovePadLevel_05("pad_05_antbait_U_sprite", scene, controls);
        pad_05_antbait_U.position = new THREE.Vector3(17.25, 3.75, -4.5);
        // const pad_05_antbait_U_sprite = new MovePad("pad_05_antbait_U_sprite", scene, controls);
        // pad_05_antbait_U_sprite.position = new THREE.Vector3(17.25, 3.75, -4.5);


        const pad_06_lifeuniterrupted01 = new MovePadLevel_00("pad_06_lifeuniterrupted01", scene, controls);
        pad_06_lifeuniterrupted01.position = new THREE.Vector3(19, .01, -19);
        const pad_06_lifeuniterrupted01_sprite = new MovePad("pad_06_lifeuniterrupted01_sprite", scene, controls);
        pad_06_lifeuniterrupted01_sprite.position = new THREE.Vector3(19, .01, -19);

        const pad_06_lifeuniterrupted01_L = new MovePadLevel_00("pad_06_lifeuniterrupted01_L", scene, controls);
        pad_06_lifeuniterrupted01_L.position = new THREE.Vector3(18, .01, -25);
        const pad_06_lifeuniterrupted01_L_sprite = new MovePad("pad_06_lifeuniterrupted01_L_sprite", scene, controls);
        pad_06_lifeuniterrupted01_L_sprite.position = new THREE.Vector3(18, .01, -25);


        const pad_06_lifeuniterrupted01_R = new MovePadLevel_00("pad_06_lifeuniterrupted01_R", scene, controls);
        pad_06_lifeuniterrupted01_R.position = new THREE.Vector3(25, .01, -17);
        const pad_06_lifeuniterrupted01_R_sprite = new MovePad("pad_06_lifeuniterrupted01_R_sprite", scene, controls);
        pad_06_lifeuniterrupted01_R_sprite.position = new THREE.Vector3(25, .01, -17);


        const pad_07_lifeuniterrupted02 = new MovePadLevel_00("pad_07_lifeuniterrupted02", scene, controls);
        pad_07_lifeuniterrupted02.position = new THREE.Vector3(-19, .01, 19);
        const pad_07_lifeuniterrupted02_sprite = new MovePad("pad_07_lifeuniterrupted02_sprite", scene, controls);
        pad_07_lifeuniterrupted02_sprite.position = new THREE.Vector3(-19, .01, 19);

        const pad_07_lifeuniterrupted02_L = new MovePadLevel_00("pad_07_lifeuniterrupted02_L", scene, controls);
        pad_07_lifeuniterrupted02_L.position = new THREE.Vector3(-18, .01, 25);
        const pad_07_lifeuniterrupted02_L_sprite = new MovePad("pad_07_lifeuniterrupted02_L_sprite", scene, controls);
        pad_07_lifeuniterrupted02_L_sprite.position = new THREE.Vector3(-18, .01, 25);

        const pad_07_lifeuniterrupted02_R = new MovePadLevel_00("pad_07_lifeuniterrupted02_R", scene, controls);
        pad_07_lifeuniterrupted02_R.position = new THREE.Vector3(-25, .01, 17);
        const pad_07_lifeuniterrupted02_R_sprite = new MovePad("pad_07_lifeuniterrupted02_R_sprite", scene, controls);
        pad_07_lifeuniterrupted02_R_sprite.position = new THREE.Vector3(-25, .01, 17);



        const pad_08_synPPM = new MovePadLevel_00("pad_08_synPPM", scene, controls);
        pad_08_synPPM.position = new THREE.Vector3(-19, .01, -19);
        const pad_08_synPPM_sprite = new MovePad("pad_08_synPPM_sprite", scene, controls);
        pad_08_synPPM_sprite.position = new THREE.Vector3(-19, .01, -19);

        const pad_08_synPPM_L = new MovePadLevel_00("pad_08_synPPM_L", scene, controls);
        pad_08_synPPM_L.position = new THREE.Vector3(-24, .01, -18);
        const pad_08_synPPM_L_sprite = new MovePad("pad_08_synPPM_L_sprite", scene, controls);
        pad_08_synPPM_L_sprite.position = new THREE.Vector3(-24, .01, -18);

        const pad_08_synPPM_R = new MovePadLevel_00("pad_08_synPPM_R", scene, controls);
        pad_08_synPPM_R.position = new THREE.Vector3(-16, .01, -24);
        const pad_08_synPPM_R_sprite = new MovePad("pad_08_synPPM_R_sprite", scene, controls);
        pad_08_synPPM_R_sprite.position = new THREE.Vector3(-16, .01, -24);

        const pad_09_vectorControl = new MovePadLevel_00("pad_09_vectorControl", scene, controls);
        pad_09_vectorControl.position = new THREE.Vector3(19, .01, 19);
        const pad_09_vectorControl_sprite = new MovePad("pad_09_vectorControl_sprite", scene, controls);
        pad_09_vectorControl_sprite.position = new THREE.Vector3(19, .01, 19);

        const pad_09_vectorControl_L = new MovePadLevel_00("pad_09_vectorControl_L", scene, controls);
        pad_09_vectorControl_L.position = new THREE.Vector3(24, .01, 18);
        const pad_09_vectorControl_L_sprite = new MovePad("pad_09_vectorControl_L_sprite", scene, controls);
        pad_09_vectorControl_L_sprite.position = new THREE.Vector3(24, .01, 18);

        const pad_09_vectorControl_R = new MovePadLevel_00("pad_09_vectorControl_R", scene, controls);
        pad_09_vectorControl_R.position = new THREE.Vector3(16, .01, 24);
        const pad_09_vectorControl_R_sprite = new MovePad("pad_09_vectorControl_R_sprite", scene, controls);
        pad_09_vectorControl_R_sprite.position = new THREE.Vector3(16, .01, 24);

        const handleClickEvent = () => {
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects(scene.children, true); //array
            if (intersects.length > 0 && intersects[0]) {
                const clickable = Clickable.clickables.get(intersects[0].object.uuid);
                if (clickable) clickable.handleClicked();
            }
        };

        let dragging = false;
        let storedDraggable = null;

        const handlePointerDownEvent = (e) => {
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects(scene.children, true);
            if (intersects.length > 0 && intersects[0]) {
                const draggable = Draggable.draggables.get(intersects[0].object.uuid);
                if (draggable) {
                    e.stopPropagation();
                    whiteboard.isDrawing = false;
                    dragging = true;
                    storedDraggable = draggable;
                }
            }
        };

        // using a plane to project the drags onto. This is not ideal and could be more
        // performant but it'll do for prototype stage
        const geo = new THREE.PlaneBufferGeometry(1000, 1000);
        const plane = new THREE.Mesh(
            geo,
            new THREE.MeshBasicMaterial({ color: "red", visible: false })
        );
        // scene.add(plane); // for some reason raycaster only works if this is in the scene
        // plane.position.set(1, 1.9, -10.5);

        const updateMouse = (e, x, y) => {
            mouse.x = (x / window.innerWidth) * 2 - 1;
            mouse.y = -(y / window.innerHeight) * 2 + 1;

            if (!dragging) return;
            whiteboard.isDrawing = false;
            e.stopPropagation();
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObject(plane, true);
            if (intersects.length > 0 && intersects[0]) {
                const pos = intersects[0].point;
                storedDraggable.handleDragged(pos);
            }
        }

        const handlePointerMoveEvent = (e) => {
            updateMouse(e, e.clientX, e.clientY);
        };

        const handleTouchMoveEvent = (e) => {
            const ex = e.changedTouches[0].pageX;
            const ey = e.changedTouches[0].pageY;
            updateMouse(e, ex, ey);
        };

        const handlePointerUpEvent = () => {
            dragging = false;
        };

        const handleClickChat = () => {
            // analytics.recordEvent("click-chat-home")
            show()
        }

        renderer.domElement.addEventListener("click", handleClickEvent, true);
        renderer.domElement.addEventListener("touchstart", handleClickEvent, true);

        renderer.domElement.addEventListener(
            "pointerdown",
            handlePointerDownEvent,
            true
        );
        renderer.domElement.addEventListener(
            "pointermove",
            handlePointerMoveEvent,
            true
        );
        renderer.domElement.addEventListener(
            "touchmove",
            handleTouchMoveEvent,
            false
        );
        renderer.domElement.addEventListener(
            "pointerup",
            handlePointerUpEvent,
            true
        );

        //Setup render loop
        const animate = () => {
            const delta = clock.getDelta();
            controls.controls.update(delta);
            controls.update();
            const cameraHeight = heightmap.update(renderer);
            controls.setCameraHeight(cameraHeight);

            // Update whiteboard //Commented out as we are not using it
            // whiteboard.update();

            requestAnimationFrame(animate);

            if (heightmap.hudEnabled) renderer.autoClear = false;

            // render 3D scene
            renderer.render(scene, camera);

            if (heightmap.hudEnabled) {
                // now render HUD (for heightmap) on top
                renderer.clearDepth();
                renderer.render(hudScene, camera);
            }
        };

        //Window resize
        const onWindowResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
            animate();
        };

        window.addEventListener("resize", onWindowResize, false);

        // const imageViewer = new ImageViewer(scene, [
        //   "assets/images/mars.jpg",
        //   "assets/images/mars-rover.jfif",
        //   "assets/images/mars-mountain.jfif",
        // ]);
        // imageViewer.setPosition(-3, -3.9, -10.4);



        const videoViewer = new VideoViewer(scene, "video-8", "assets/videos/03_ALTRISET_V/Video_Altriset.jpg", 2.8, 1.55);
        videoViewer.setPosition(-6.667418, 2.10376, -18.73);
        videoViewer.setRotation(0, 0, 0);

        //upstairs?
        const videoViewer2 = new VideoViewer(scene, "video-2", "assets/videos/01_ADVION_V/Video2_Advion Cockroach.jpg");
        videoViewer2.setPosition(17.34, 2.05376, -5.1966);
        videoViewer2.setRotation(0, -90, 0);
        //downstairs?
        const videoViewer2u = new VideoViewer(scene, "video-1", "assets/videos/01_ADVION_V/Video1_ADVION.jpg");
        videoViewer2u.setPosition(23.25, 6.1878, 1.47702);
        videoViewer2u.setRotation(0, -90, 0);

        const videoViewer3 = new VideoViewer(scene, "video-7", "assets/videos/04_TALON_V/Video_Klerat.jpg");
        videoViewer3.setPosition(6.845, 2.05376, 19.335);
        videoViewer3.setRotation(0, 180, 0);
        const videoViewer4 = new VideoViewer(scene, "video-9", "assets/videos/02_DEMAND_V/Video_Demand.jpg");
        videoViewer4.setPosition(-16.9, 2.05376, 6.744);
        videoViewer4.setRotation(0, 90, 0);
        const videoViewer4u = new VideoViewer(scene, "video-10", "assets/videos/02_DEMAND_V/Video_Arilon.jpg");
        videoViewer4u.setPosition(-22.879, 6.25353, 1.09246);
        videoViewer4u.setRotation(0, 90, 0);
        const videoViewer5 = new VideoViewer(scene, "video-5", "assets/videos/07_PPM_V/10_PPM.jpg", 3.9, 2.333,);
        videoViewer5.setPosition(29.4, 3.42606, -24.153);
        videoViewer5.setRotation(0, -90, 0);
        const videoViewer5b = new VideoViewer(scene, "video-3", "assets/videos/lifeInterrupted.jpg", 3.9, 2.333,);
        videoViewer5b.setPosition(-29.4, 3.42606, 24.153);
        videoViewer5b.setRotation(0, 90, 0);
        const videoViewer7 = new VideoViewer(scene, "video-6", "assets/videos/06_SPECIALTY_V/specialty.jpg", 3.9, 2.333);
        videoViewer7.setPosition(-25.20901, 3.0404, -26.403);
        videoViewer7.setRotation(0, 45, 0);

        //videoViewer6 = vectorControl.png - NO VIDEO!!! now a poster
        // const videoViewer6 = new VideoViewer(scene, "video-6", "assets/pdf/missing.jpg", 3.9, 2.333);
        // videoViewer6.setPosition(24.15056, 3.42606, 29.4);
        // videoViewer6.setRotation(0, 180, 0);

        //Update collision objects for player
        //question from Chris - this stops all colllisions - so I commeted it out***
        // controls.setCollisionObjects(scene.children.filter(obj => MovePad.pads.includes(obj)));

        animate();

        return () => {
            window.removeEventListener("resize", onWindowResize);
            renderer.domElement.removeEventListener("click", handleClickEvent);
            renderer.domElement.removeEventListener("touchstart", handleClickEvent);
            renderer.domElement.removeEventListener(
                "pointerdown",
                handlePointerDownEvent
            );
            renderer.domElement.removeEventListener(
                "pointermove",
                handlePointerMoveEvent
            );
            renderer.domElement.removeEventListener(
                "touchmove",
                handleTouchMoveEvent
            );
            renderer.domElement.removeEventListener(
                "pointerup",
                handlePointerUpEvent
            );
        };
    }, []);

    const [textFieldOpen, setTextFieldOpen] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [chatOpen, setChatOpen] = useState(false);
    const [chatModalOpen, setChatModalOpen] = useState(false);
    const [helpModalOpen, setHelpModalOpen] = useState(false);
    const [emailModalOpen, setEmailModalOpen] = useState(false);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [welcomeModalOpen, setWelcomeModalOpen] = useState(true);

    const onChangeTextField = (value) => {
        setTextFieldValue(value);
    };

    const onConfirmTextField = () => {
        setTextFieldOpen(false);
        setTextFieldValue("");
        whiteboard?.setCurrentTextValue(textFieldValue);
        whiteboard.enableDrawingMode();
    };

    const onCancelTextField = () => {
        setTextFieldOpen(false);
        setTextFieldValue("");
        whiteboard?.deleteCurrentTextField();
    };

    const [incompatibleOpen, setIncompatibleOpen] = useState(false);

    useEffect(() => {
        setIncompatibleOpen(!IsBrowserCompatible());
    }, [])

    return (
        <>
            {/*//upstairs*/}
            <video id="video-1" loop crossOrigin="anonymous" playsInline style={{display:"none"}}>
                <source src="assets/videos/01_ADVION_V/Video1_ADVION.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>

            {/*//downstairs*/}
            <video id="video-2" loop crossOrigin="anonymous" playsInline style={{display:"none"}}>
                <source src="assets/videos/01_ADVION_V/Video2_Advion Cockroach.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>


            <video id="video-3" loop crossOrigin="anonymous" playsInline style={{display:"none"}}>
                <source src="assets/videos/lifeInterrupted.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>

            <video id="video-5" loop crossOrigin="anonymous" playsInline style={{display:"none"}}>
                <source src="assets/videos/07_PPM_V/10_PPM.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>

            <video id="video-6" loop crossOrigin="anonymous" playsInline style={{display:"none"}}>
                <source src="assets/videos/06_SPECIALTY_V/specialty.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>

            <video id="video-7" loop crossOrigin="anonymous" playsInline style={{display:"none"}}>
                <source src="assets/videos/04_TALON_V/Video_Klerat.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>

            <video id="video-8" loop crossOrigin="anonymous" playsInline style={{display:"none"}}>
                <source src="assets/videos/03_ALTRISET_V/Video_Altriset.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>

            <video id="video-9" loop crossOrigin="anonymous" playsInline style={{display:"none"}}>
                <source src="assets/videos/02_DEMAND_V/Video_Demand.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>

            <video id="video-10" loop crossOrigin="anonymous" playsInline style={{display:"none"}}>
                <source src="assets/videos/02_DEMAND_V/Video_Arilon.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
            </video>

            <TextFieldDialog
                open={textFieldOpen}
                value={textFieldValue}
                onChange={onChangeTextField}
                onClose={onConfirmTextField}
                onCancel={onCancelTextField}
            />

            {/*<chatModal*/}
            {/*    id="popup-chat"*/}
            {/*    open={helpModalOpen}*/}
            {/*    onClose={() => setHelpModalOpen(false)}*/}
            {/*    src="assets/images/masthead.jpg"//?????*/}
            {/*/>*/}

            <HelpModal
                id="popup-help"
                open={helpModalOpen}
                onClose={() => setHelpModalOpen(false)}
                src="assets/images/masthead.jpg"//?????
            />

            <EmailModal
                id="popup-email"
                open={emailModalOpen}
                onClose={() => setEmailModalOpen(false)}
                src="assets/images/masthead.jpg"//?????
            />

            <ImageModal
                id="popup-contact"
                open={imageModalOpen}
                onClose={() => setImageModalOpen(false)}
                src="assets/images/SyngentaPPM-dl.jpg"
            />
            <WelcomeModal
                id="popup-welcome"
                open={welcomeModalOpen}
                onClose={() => setWelcomeModalOpen(false)}
                src="assets/images/masthead.jpg"
            />
            <Dialog open={incompatibleOpen}>
                <DialogActions>
                    <Button onClick={() => setIncompatibleOpen(false)}>Close X</Button>
                </DialogActions>

                <DialogTitle>You are using an Incompatible Browser</DialogTitle>
                <DialogContent>
                    To enjoy this site at it's best please use a chrome based browser.
                </DialogContent>

                <DialogContent>
                    Using your current browser may result in a poor viewing experience.
                </DialogContent>

                <DialogContent>
                </DialogContent>
                <DialogContent>
                </DialogContent>
                <DialogContent>
                </DialogContent>

            </Dialog>

            {/*/!* Loading screen  put cool spinner here*!/*/}
            {showLoadingScreen && (
                <Box
                    width="100%"
                    height="100%"
                    position="absolute"
                    bgcolor="#ffffff"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    zIndex={200}
                >
                    <img src="assets/images/PPMloading.gif"/>
                </Box>


            )}

            {/*<Box position="absolute" top={0} right={0}>*/}
            <Box position="absolute" bottom={0} width={"100%"} >
                <div style={{margin: "0 auto", width: "200px", textAlign: "center"}}>
                    <Button variant="contained" onClick={() => controls.resetPosition()}>⌂ Restart</Button>
                    <Button variant="contained" onClick={() => setWelcomeModalOpen(true)}>? help</Button>
                </div>
                {/*🏠*/}
            </Box>
            <div ref={mountRef} />
        </>
    );
};

export default BasicThreeExample;
