import * as THREE from "three";
import * as holdEvent from "hold-event";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {Vector3} from "three";
import MovePad from "./MovePad";

// const EPS = 1e-5;
const EPS = 0.02;
const raycaster = new THREE.Raycaster();


export default class LookControlsV2{

    lerpAlpha = 0.03;
    m_enabled = true;

    constructor(camera, renderer, scene, ) {

        this.scene = scene;

        this.cameraHeight = 1.6;

        // in order to archive FPS look, set EPSILON for the distance to the center
        camera.position.set(0, this.cameraHeight, EPS);

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        this.controls = controls;
        controls.target = new THREE.Vector3(0, this.cameraHeight, 0)
        controls.update();

        const KEYCODE = {
            W: 87,
            A: 65,
            S: 83,
            D: 68,
            ARROW_LEFT : 37,
            ARROW_UP   : 38,
            ARROW_RIGHT: 39,
            ARROW_DOWN : 40,
        };

        const moveSpeed = 0.0025;
        const delay = 10;

        //
        const wKey = new holdEvent.KeyboardKeyHold( KEYCODE.W, delay );
        const aKey = new holdEvent.KeyboardKeyHold( KEYCODE.A, delay );
        const sKey = new holdEvent.KeyboardKeyHold( KEYCODE.S, delay );
        const dKey = new holdEvent.KeyboardKeyHold( KEYCODE.D, delay );
        aKey.addEventListener( 'holding', (event) => { this.moveLeft(moveSpeed * event.deltaTime) });
        dKey.addEventListener( 'holding', (event) => { this.moveRight(moveSpeed * event.deltaTime) });
        wKey.addEventListener( 'holding', (event) => { this.moveForward(moveSpeed * event.deltaTime) });
        sKey.addEventListener( 'holding', (event) => { this.moveBackward(moveSpeed * event.deltaTime) });

        const leftKey  = new holdEvent.KeyboardKeyHold( KEYCODE.ARROW_LEFT,  delay );
        const rightKey = new holdEvent.KeyboardKeyHold( KEYCODE.ARROW_RIGHT, delay );
        const upKey    = new holdEvent.KeyboardKeyHold( KEYCODE.ARROW_UP,    delay );
        const downKey  = new holdEvent.KeyboardKeyHold( KEYCODE.ARROW_DOWN,  delay );
        leftKey.addEventListener('holding', (event) => { this.moveLeft(moveSpeed * event.deltaTime) });
        rightKey.addEventListener('holding', (event) => { this.moveRight(moveSpeed * event.deltaTime) });
        upKey.addEventListener('holding', (event) => { this.moveForward(moveSpeed * event.deltaTime) });
        downKey.addEventListener('holding', (event) => { this.moveBackward(moveSpeed * event.deltaTime) });

        this.collisionObjects = this.scene.children;

        this.controls = controls;
        this.camera = camera;
    }

    setCameraHeight(height) {
        this.cameraHeight = height;
    }

    moveForward(speed){
        if(!this.enabled) return;
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        this.move(direction, speed)
    }

    moveLeft(speed){
        if(!this.enabled) return;
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        const axis = new THREE.Vector3(0, 1, 0);
        const angle = Math.PI / 2;
        direction.applyAxisAngle( axis, angle );
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        this.move(direction, speed)
    }

    moveRight(speed){
        if(!this.enabled) return;
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        const axis = new THREE.Vector3(0, 1, 0);
        const angle = -Math.PI / 2;
        direction.applyAxisAngle( axis, angle );
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        this.move(direction, speed)
    }

    moveBackward(speed){
        if(!this.enabled) return;
        const direction = this.controls.target.clone().sub(this.camera.position.clone());
        direction.projectOnPlane(new THREE.Vector3(0, 1, 0))
        this.move(direction.negate(), speed)
    }

    move(direction, speed){
        if(!this.enabled) return;

        this.targetPosition = undefined;

        const rayPosition = new THREE.Vector3(this.camera.position.x, this.camera.position.y - 0.5, this.camera.position.z);
        raycaster.set(rayPosition, direction);
        raycaster.camera = this.camera;
        const intersects = raycaster.intersectObjects(this.collisionObjects, true);
        if(intersects.length > 0 && intersects[0].distance < 0.25) return;

        const newTargetPos = new THREE.Vector3();
        const newCameraPos = new THREE.Vector3();
        newCameraPos.addVectors(direction.normalize().multiplyScalar(speed), this.camera.position);
        newTargetPos.addVectors(direction.normalize().multiplyScalar(speed), this.controls.target);

        this.controls.target.set(newTargetPos.x, newTargetPos.y + (this.cameraHeight - newCameraPos.y), newTargetPos.z);
        this.camera.position.set(newCameraPos.x, this.cameraHeight, newCameraPos.z);
        this.controls.update();
        if (this.postMove) this.postMove(newCameraPos);
    }

    moveTo(position){
        if(!this.enabled) return;

        this.targetPosition = position;
    }

    setCollisionObjects = (objects) => {
        this.collisionObjects = objects;
    }

    resetPosition(){
        this.targetPosition = new THREE.Vector3(0, 1.8,  0)    }

    update(){

        // if(!this.enabled) return;

        if(this.targetPosition){
            if(this.controls.target.distanceTo(this.targetPosition) <= 0.2){
                console.log("Target reached.")
                this.targetPosition = undefined;
            }
            else{
                const cameraDirection = this.camera.position.clone().sub(this.controls.target.clone()).normalize()

                // We calculate a position offset from the real target
                // It must be offset in the direction of the (camera - orbit target) so that the camera never gets too close to the target.
                // Not doing this locks the camera orbit.
                // We do this every frame to ensure a smooth transition.
                const cameraTargetPosition = new THREE.Vector3();
                cameraTargetPosition.addVectors(cameraDirection.multiplyScalar(EPS), this.targetPosition);

                this.controls.target.lerp(this.targetPosition, this.lerpAlpha)
                this.camera.position.lerp(cameraTargetPosition, this.lerpAlpha)
            }
        }

        this.controls.maxDistance = EPS;
    }

    get enabled(){
        return this.m_enabled;
    }

    set enabled(state){
        this.m_enabled = state;
        this.controls.enabled = state;
    }
}