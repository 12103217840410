const dev = {
    api: "https://oslvy6psvi.execute-api.eu-central-1.amazonaws.com",
    sentry: {
        enabled: true
    }
}

const prod = {
    api: "https://b0i85bthxa.execute-api.eu-central-1.amazonaws.com",
    sentry: {
        enabled: true
    }
}

// Default to dev if not set
const config = (function ()
{
  switch (process.env.NEXT_PUBLIC_REACT_APP_STAGE)
  {
    case "prod":
      return prod;
    case "dev":
      return dev;
    default:
      return dev;
  }
})(dev);

export default {
  ...config
};
