import * as THREE from 'three';
import Clickable from "./Clickable";
import UnlitImageMaterial from "./UnlitMaterial";

export default class ModalTrigger {

    constructor(scene, onClick, src) {
        this.scene = scene;
        const geometry = new THREE.PlaneBufferGeometry(2, 2);
        const material = new UnlitImageMaterial({ src });

        const board = new THREE.Mesh(geometry, material);
        material.obj = board;
        this.board = board;

        this.scene.add(this.board);
        this.board.position.set(-27.619, 1.4, -19.03);
        this.board.rotateY(90 * THREE.Math.DEG2RAD);

        this.board.scale.set(.33,.33,.33);

        const handleClick = (clickable) => {
            onClick(true);
        }

        new Clickable(this.board, handleClick)
    }

    setPosition = (x, y, z) => {
        this.board.position.set(x, y, z);
    }

    setRotation = (y) => {
        this.board.rotateY(y * THREE.Math.DEG2RAD);
        //this.board.rotation.setFromVector3(h, Math.PI/p, b);
        //this.board.rotation.setFromVector3(new THREE.Vector3 ( x, Math.PI/y , z) );
    }
}
