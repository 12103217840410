import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import React, {useEffect} from "react";
import {RecordEvent} from "../libs/AnalyticsLib";

export default function HelpModal(props) {
  const { id, open, src, onClose } = props;

  useEffect(() => {
      if(open){
          RecordEvent({
              type: "welcome-popup-open",
              id

          })
      }else{
          RecordEvent({
              type: "welcome-popup-close",
              id
          })
      }
  }, [open])

  return (
    <Dialog open={open} fullWidth>

        <DialogActions>
  <Button onClick={() => onClose()}>Close X</Button>
        </DialogActions>



        <DialogContent>
            <img class="image-modal-img" src={src} />
        </DialogContent>

      <DialogContent>

          <p>
              Instructions:
          </p>
        <p>
        {/*  wasd=move*/}
        {/*</p><p>*/}
          {/*🡸🡹🡺=move*/}
          Use the Arrow keys or WASD keys to walk around.
        </p>
          <p>
          use the mouse to click and drag to look around.
        </p>
          <p>
          click on the icons to teleport.
        </p>
          <p>
          click on the stairs icon to go upstairs.
        </p>
          <p>
          click on the chat icon to speak to someone from Syngenta.
        </p>
      </DialogContent>

    </Dialog>
  );
}
