import { v1 as uuid } from "uuid";
import config from "../config";

const endpoint = config.api;
const local_storage_key = "analytics_user_id";

export async function GetUserId(){
    let id = localStorage.getItem(local_storage_key);
    if(!id){
        id = uuid();
        await localStorage.setItem(local_storage_key, id);
    }
    return id;
}

export async function RecordEvent(event){

    const user_id = await GetUserId();

    console.log(user_id)

    await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({
            ...event,
            user_id,
        })
    });
}