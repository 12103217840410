import './App.css';
import BasicThreeExample from './components/BasicThreeExample';
import { Auth0Provider } from '@auth0/auth0-react';
import AppBar from './components/AppBar';
import React, { useEffect } from 'react';
import config from './config';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { IntercomProvider, useIntercom } from 'react-use-intercom';

function App()
{
    useEffect(() => {
        if(config.sentry.enabled){
            Sentry.init({
                dsn: "https://4bf6b06a110b452f9629bc1ed46f6f4e@o468348.ingest.sentry.io/5673439",
                integrations: [new Integrations.BrowserTracing()],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,
            });
        }
    }, [])

    return (
        <IntercomProvider autoBoot appId="lqu6tp18">
            <div style={{height: '100%', width: "100%"}}>
                <BasicThreeExample />
            </div>
         </IntercomProvider>
    );
}

export default App;
