import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import LoadingManager from "../utilities/LoadingManager";

export default class HDREnvironment {
    constructor(scene, renderer, path) {

        const pmremGenerator = new THREE.PMREMGenerator( renderer );
        pmremGenerator.compileEquirectangularShader();

        new RGBELoader()
            .setDataType( THREE.UnsignedByteType )
            .load( path, function ( texture ) {

                const envMap = pmremGenerator.fromEquirectangular( texture ).texture;

                // scene.background = envMap;
                scene.environment = envMap;

                texture.dispose();
                pmremGenerator.dispose();
            });
    }
}