import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
    Link
} from "@material-ui/core";


// import {
//     Box,
//     Button,
//     DialogActions,
//     DialogContent,
//     TextField,
//     Grid,
//     Typography,
//     Link
// } from '@material-ui/core'
import React, {useEffect} from "react";
import {RecordEvent} from "../libs/AnalyticsLib";

export default function EmailModal(props) {
  const { id, open, src, onClose } = props;

  useEffect(() => {
      if(open){
          RecordEvent({
              type: "email-popup-open",
              id

          })
      }else{
          RecordEvent({
              type: "email-popup-close",
              id
          })
      }
  }, [open])

  return (
    <Dialog open={open} fullWidth>

        <DialogActions>
  <Button onClick={() => onClose()}>Close X</Button>
        </DialogActions>



        <DialogContent>
            <img class="image-modal-img" src={src} />
        </DialogContent>

      <DialogContent>

          <p>
           Click here to email support:
          </p>
          <Link href="mailto:edwin.lim@syngenta.com" variant="body2"  target="_blank">edwin.lim@syngenta.com</Link>
          {/*window.open("mailto:Suzanne.Renwick@myeloma.org.uk", '_blank')*/}


        <p>

        </p>
      </DialogContent>

    </Dialog>
  );
}
