import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import React, {useEffect} from "react";
import {RecordEvent} from "../libs/AnalyticsLib";

export default function ImageModal(props) {
  const { id, open, src, onClose } = props;

  useEffect(() => {
      if(open){
          RecordEvent({
              type: "image-popup-open",
              id

          })
      }else{
          RecordEvent({
              type: "image-popup-close",
              id
          })
      }
  }, [open])

  return (
    //<Dialog open={open} fullWidth>
        <Dialog open={open} fullScreen>
            <DialogActions>
                <Button onClick={() =>        window.open("/assets/pdf/SyngentaPPM-dl.pdf", "_blank")                    }   >Click here to download the contact info</Button>
                <Button onClick={() => onClose() }>Close X</Button>
            </DialogActions>

            {/*<DialogActions>*/}
            {/*    <Button onClick={() =>        window.open("/assets/pdf/SyngentaPPM-dl.pdf", "_blank")                    }   >Click here to download the contact info</Button>*/}
            {/*</DialogActions>*/}

      <DialogContent>
        <img class="image-modal-img" src={src} />
      </DialogContent>
    </Dialog>
  );
}
