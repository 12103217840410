import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";

export default function TextFieldDialog(props) {
  const { open, value, onChange, onCancel, onClose } = props;

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogContent>
        <TextField
          variant="outlined"
          autoFocus
          fullWidth
          multiline={true}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button onClick={() => onClose()}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
