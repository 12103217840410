import * as THREE from 'three';
import Clickable from "./Clickable";
import {RecordEvent} from "../libs/AnalyticsLib";

export default class MovePad  {

    static pads = []

    constructor(id, scene, controls) {
        this.id = id;
        this.controls = controls;
        
        MovePad.pads.push(this);
        
        // const material = new THREE.MeshBasicMaterial( {
        //     map: new THREE.TextureLoader().load("assets/textures/hotspot.png")
        // });
        // const circle = new THREE.Mesh( geometry, material );
        // circle.rotateX(- Math.PI / 2)
        // scene.add( circle );
        // this.object = circle;

        const material = new THREE.SpriteMaterial({
            // map: new THREE.TextureLoader().load("assets/textures/hotspot.png"),
            map: new THREE.TextureLoader().load("assets/textures/trans.png"),
            transparent: true
        })
        const sprite = new THREE.Sprite(material);
        this.object = sprite;

        scene.add(sprite);

        sprite.scale.set(2, 2, 2);
        new Clickable(sprite, this.handleClick)
    }

    set position(position){
        this.object.position.set(position.x, position.y, position.z)
    }

    handleClick = async () => {
        this.controls.moveTo(new THREE.Vector3(this.object.position.x, 1.8, this.object.position.z));
        await RecordEvent({
            type: "pad_Sprite",
            id: this.id
        })
    }
}