import * as THREE from 'three';
import Clickable from "./Clickable";
import {RecordEvent} from "../libs/AnalyticsLib";

export default class MovePadLevel_00  {

    constructor(id, scene, controls) {
        this.id = id;
        this.controls = controls;
        const geometry = new THREE.CircleGeometry( .8, 32 );
        //const material = new THREE.MeshBasicMaterial( { color: 0x85bd24 } );
        const material = new THREE.MeshBasicMaterial( {
            map: new THREE.TextureLoader().load("assets/icons/ZoneVS-teleport.png")
        });

        const circle = new THREE.Mesh( geometry, material );
        circle.rotateX(- Math.PI / 2)
        scene.add( circle );

        this.object = circle;
        new Clickable(circle, this.handleClick)
    }

    set position(position){
        this.object.position.set(position.x, position.y, position.z)
        this.object.rotateY(0 * THREE.Math.DEG2RAD);
    }




    handleClick = async () => {
        this.controls.moveTo(new THREE.Vector3(this.object.position.x, 1.8, this.object.position.z));
        await RecordEvent({
            type: "pad",
            pad_id: this.id
        })
    }
}