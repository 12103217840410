import * as THREE from 'three';
import Clickable from "./Clickable";
import UnlitImageMaterial from "./UnlitMaterial";

export default class TriggerCube {

    constructor(scene, onClick, src) {
        this.scene = scene;
        const geometry = new THREE.BoxGeometry(.5, 2.5,2.5);
        //const material = new UnlitImageMaterial({ src });

        // const board = new THREE.Mesh(geometry, material);
        const board = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({ transparent: true ,opacity: 0.0}));
        //material.obj = board;
        this.board = board;

        this.scene.add(this.board);
        this.board.position.set(-27.619, 1.4, -19.03);
        this.board.rotateY(90 * THREE.Math.DEG2RAD);

        this.board.scale.set(.33,.33,.33);

        const handleClick = (clickable) => {
            onClick(true);
        }

        new Clickable(this.board, handleClick)
    }

    setPosition = (x, y, z) => {
        this.board.position.set(x, y, z);
    }

    setRotation = (x,y,z) => {
        // const euler = new THREE.Euler(
        //   THREE.MathUtils.degToRad(x),
        //     THREE.MathUtils.degToRad(y),
        //     THREE.MathUtils.degToRad(z),
        //
        // )
        // this.board.setRotationFromEuler(euler);

        this.board.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), THREE.MathUtils.degToRad(x));
        this.board.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(y));
        this.board.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), THREE.MathUtils.degToRad(z));

        //this.board.rotation.setFromVector3(h, Math.PI/p, b);
        //this.board.rotation.setFromVector3(new THREE.Vector3 ( x, Math.PI/y , z) );
    }
}
