export default class Clickable {

    static clickables = new Map();

    constructor(object, onClick, meta) {
        this.object = object;
        this.onClick = onClick;
        this.meta = meta;
        Clickable.clickables.set(object.uuid, this)
    }

    handleClicked() {
        this.onClick(this);
    }

    dispose(){
        delete Clickable.clickables.delete(this.object.uuid)
    }
}